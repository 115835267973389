import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "./loading";
import EvaluationStatusEnum from "./evaluationStatusEnum";
import EnterEntryCodeForm from "./enterEntryCodeForm";
import Begin from "./begin";
import NewAttempt from "./newAttempt";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";
import DefaultNotAble from "./defaultNotAble";
import Continue from "./continue";

const Evaluation = () => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    const { courseCode, assessmentCode } = useParams();
    const [status, setStatus] = useState("");
    const [beginOrNew, setBeginOrNew] = useState("");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);

    function updateStatus(status) {
        setStatus(status);
    }

    function updateBeginOrNew(string) {
        setBeginOrNew(string);
    }

    const findStatusOfEval = async () => {
        try {
            setRequestLifeCycle(true);
            const response = await fetch( process.env.REACT_APP_EVALUATION_API_URL  + `/get-evaluation-status/${courseCode}/${assessmentCode}`, {
                method: "GET",
                credentials: "include"
            });            
            if (response.status === 200) {
                setStatus(EvaluationStatusEnum.CONTINUE)
            } else if (response.status === 450) {
                setStatus(EvaluationStatusEnum.BEGIN)
            } else if (response.status === 451) {
                setStatus(EvaluationStatusEnum.OUTSIDE_TIME_RANGE)
            } else if (response.status === 452) {
                setStatus(EvaluationStatusEnum.OUT_OF_ATTEMPTS)
            } else if (response.status === 453) {
                setStatus(EvaluationStatusEnum.NEW_ATTEMPT)
            } else if (response.status === 401) {
                navigateToLoginPageEnv();
            } else {
                updateStatus(EvaluationStatusEnum.ERROR)
            }
        } catch (error) {
            updateStatus(EvaluationStatusEnum.ERROR)
        } finally {
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        findStatusOfEval();
    }, [])

    const pageToReturn = () => {
        switch (status) {
            case EvaluationStatusEnum.OUTSIDE_TIME_RANGE: {
                return <DefaultNotAble />
            }
            case EvaluationStatusEnum.OUT_OF_ATTEMPTS: {
                return <DefaultNotAble />
            }
            case EvaluationStatusEnum.NOT_ENTERED_ENTRY_CODE: {
                return <EnterEntryCodeForm beginOrNew={beginOrNew} updateStatus={updateStatus}/>
            }
            case EvaluationStatusEnum.BEGIN: {
                return <Begin updateBeginOrNew={updateBeginOrNew} updateStatus={updateStatus}/>
            }
            case EvaluationStatusEnum.NEW_ATTEMPT: {
                return <NewAttempt updateBeginOrNew={updateBeginOrNew} updateStatus={updateStatus}/>
            }
            case EvaluationStatusEnum.CONTINUE: {
                return <Continue />
            }
            case EvaluationStatusEnum.ERROR: {
                return <DefaultNotAble />
            }
            default: {
                return <DefaultNotAble />
            }
        }
    }

    return (
        <>
            {
                requestLifeCycle

                ?

                <>
                    <div className="total-2">
                        <Loading />
                    </div>
                </>

                :

                <>
                        { pageToReturn() }                        
                </>

            }
        </>
    );
}

export default Evaluation;