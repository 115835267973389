import React from "react";

const ASingleTestLambdaGrading = ({ greyedOut, testName, pointsEarned, passed, output, passPoints }) => {
    return (
        <>
            <div className="if-output-container">
                <div className="a-single-test" style={{ color: greyedOut ? "var(--gray-six)" : (passed ? "var(--correct)" : "var(--incorrect)"), borderRadius: output ? "5px 5px 0px 0px" : "" }}>
                    <div className="left-of-test">
                        { testName ? testName : "<Missing name>"}
                    </div>
                    <div className="right-of-test">
                    { (pointsEarned ? Number(pointsEarned).toFixed(2) : "0.00") + "/" + (passPoints ? Number(passPoints).toFixed(2) : "0.00") + " pts"}
                    </div>
                </div>
                {
                    output

                    ?

                    <div className="bottom-output" style={{ color: greyedOut ? "var(--gray-six)" : "" }}>
                        { output }
                    </div>

                    :

                    <>
                    </>

                }
            </div>
        </>
    );
}

export default ASingleTestLambdaGrading;