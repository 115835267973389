import React, { useState, useEffect } from "react";
import TopRightCustomExam from "./topRightCustom";
import CountdownTimer from "./coutdownTimer";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "./useWindowWidth";

const NavTakeEvaluation = ({ updateTestsConfigDict, updateRequestLifeCycleFour, updateError, flags, updateFetchError, updateStatus, updateAttempt, coupledProgrammingQuestions, allowedAttemptsProgramming, attempt, inKioskParent, times, contentsNeedToBeSaved, updateContentsNeedToBeSaved, contents, updateQuestionsToSave, questionsToSave }) => {    
    const navigate = useNavigate();
    const widthTwo = useWindowWidth(435);
    const [autoSubmit, setAutoSubmit] = useState(false);
    const [inKiosk, setInKiosk] = useState(false)

    function updateAutoSubmit(autoSubmit) {
        setAutoSubmit(autoSubmit);
    }

    useEffect(() => {
        setInKiosk(inKioskParent)
    }, [inKioskParent])

    // battery status autosubmit
    useEffect(() => {
        const handleBatteryStatus = async () => {
            if ('getBattery' in navigator) {
                const battery = await navigator.getBattery();
        
                const handleBatteryLevelChange = async () => {
                    const batteryPercentage = battery.level * 100;
        
                    if (batteryPercentage <= 3 && inKiosk) {
                        if (inKiosk) {
                            updateAutoSubmit(true);
                        }
                        battery.removeEventListener('levelchange', handleBatteryLevelChange);
                    }
                };
        
                // Set up the battery level change listener
                battery.addEventListener('levelchange', handleBatteryLevelChange);
        
                // Clean up the event listener on component unmount
                return () => {
                    battery.removeEventListener('levelchange', handleBatteryLevelChange);
                };
            }
        };
    
        // Attach the battery event listener when the component mounts
        handleBatteryStatus();
    }, []); 
    
    // battery status warning
    useEffect(() => {
        const handleBatteryStatus = async () => {
            if ('getBattery' in navigator) {
                const battery = await navigator.getBattery();
        
                const handleBatteryLevelChange = () => {
                    const batteryPercentage = battery.level * 100;
        
                    if (batteryPercentage <= 10 && inKiosk) {
                        alert("Battery level is low. Your evaluation will autosubmit at 3% or less. No programming autograding will occur.");
                        battery.removeEventListener('levelchange', handleBatteryLevelChange);
                    }
                };
        
                // Set up the battery level change listener
                battery.addEventListener('levelchange', handleBatteryLevelChange);
        
                // Clean up the event listener on component unmount
                return () => {
                    battery.removeEventListener('levelchange', handleBatteryLevelChange);
                };
            }
        };
    
        // Attach the battery event listener when the component mounts
        handleBatteryStatus();
    }, []);
    
    return (
        <>
            <div className="nav-container">
                <div className="nav-sub-take-container">
                    {
                        !widthTwo && (
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16 16">
                                    <path fill="#0B73FE" stroke="#0B73FE" strokeWidth=".3" d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707z"/>
                                </svg>
                            </div>
                        )
                    }
                    <CountdownTimer times={times} updateAutoSubmit={updateAutoSubmit} autoSubmit={autoSubmit}/>
                </div>                
                <TopRightCustomExam updateTestsConfigDict={updateTestsConfigDict} updateRequestLifeCycleFour={updateRequestLifeCycleFour} updateError={updateError}  flags={flags} updateFetchError={updateFetchError} updateStatus={updateStatus} updateAttempt={updateAttempt} coupledProgrammingQuestions={coupledProgrammingQuestions} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} autoSubmit={autoSubmit} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} contents={contents} updateQuestionsToSave={updateQuestionsToSave} questionsToSave={questionsToSave} />
            </div>
        </>
    );
}

export default NavTakeEvaluation;