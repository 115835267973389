import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import EvaluationStatusEnum from "./evaluationStatusEnum";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";
import Loading from "./loading";

const NewAttempt = ({ updateStatus, updateBeginOrNew }) => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);

    const handleClick = async () => {
        if (requestLifeCycle) {
            return;
        }
        
        try {
            setRequestLifeCycle(true);
            const response = await fetch( process.env.REACT_APP_EVALUATION_API_URL  + `/begin-new-attempt`, {
                method: "POST",
                credentials: "include",  
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    entryCode: ""
                })
            })
            if (response.status === 200) {
                try {
                    navigator.clipboard.writeText("")
                } catch (error) {
                    console.log(error)
                }
                localStorage.setItem(assessmentCode, "true");
                navigate(`/${courseCode}/${assessmentCode}/take-evaluation`);
            } else if (response.status === 450) {
                updateStatus(EvaluationStatusEnum.BEGIN);
            } else if (response.status === 451) {
                updateStatus(EvaluationStatusEnum.OUTSIDE_TIME_RANGE);
            } else if (response.status === 452) {
                updateStatus(EvaluationStatusEnum.OUT_OF_ATTEMPTS);
            } else if (response.status === 453) {
                updateStatus(EvaluationStatusEnum.NOT_ENTERED_ENTRY_CODE);
                updateBeginOrNew("new");
            } else if (response.status === 401) {
                navigateToLoginPageEnv();
            } else if (response.status === 455) {
                updateStatus(EvaluationStatusEnum.NOT_ENTERED_ENTRY_CODE);
                updateBeginOrNew("new");
            } else {
                updateStatus(EvaluationStatusEnum.ERROR)
            }
        } catch(error) {
            updateStatus(EvaluationStatusEnum.ERROR)
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }
    }
    
    return (
        <>
            {
                requestLifeCycle

                ?

                <>
                    <div className="total-2">
                        <Loading />
                    </div>
                </>

                :
            
                <div className="top-right-item" onClick={handleClick} style={{ width: "160px", minWidth: "160px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z"/>
                    </svg>
                    New Attempt
                </div>

            }
        </>
    );
}

export default NewAttempt;