import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import DarkThemeQuestion from "./darkThemeQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";
import Explanation from "./explanation";
import Loading from "./loading";
import ASingleTestLambdaGrading from "./aSingleTestLambdaGrading";
import FullScreenToggler from "./fullScreenToggler";
import { useLocation, useNavigate } from "react-router-dom";
import RunNewTests from "./runNewTests";
import FlagUpdate from "./flagUpdate";

const LambdaResponseView = ({ flags, updateFlags, contentsNeedToBeSaved, updateContentsNeedToBeSaved, contents, questionsToSave, status, attempt, allowedAttempts, endedExam, results, testsMap, requestLifeCycleTwo, explanation, useExplanation, useSyntaxHighlighting, updateQuestionsToSave, useSyntax, useAutocompletion, questionNumber, question, language, isDarkTheme, updateIsDarkTheme, points, grading, uuid, studentAnswer, pointsEarned }) => {
    const [answer, setAnswer] = useState(studentAnswer);
    const [initCall, setInitCall] = useState(true);
    const [fullscreen, setFullscreen] = useState(false);
    const locationURL = useLocation();
    const navigate = useNavigate();

    const handleChange = (value) => {
        if (!grading) {
            setAnswer(value)
        }
    }

    useEffect(() => {
        // init checking
        if (initCall) {
            setInitCall(false);
            return;
        }

        // Construct new body
        const body = {
            answer: answer
        };

        // Update questions to save
        if (!grading) {
            updateQuestionsToSave((prev) => ({
                ...prev,
                [uuid]: body
            }));
        }
        
    }, [answer]);

    const determineIfWeHaveATestToShow = () => {
        const mapsLength = testsMap && Object.keys(testsMap).length > 0;

        if (results && typeof results === "object" && Object.keys(results).length > 0 && mapsLength) {

            for (const key of Object.keys(results)) {
                if (key in testsMap) {
                    return true;
                }
            }
        } else if (results && typeof results === "string" && mapsLength) {
            return true;
        }

        return false;
    }

    function updateFullscreen(fullscreen) {
        setFullscreen(fullscreen);
    }

    const setNewParams = () => {        
        const searchParams = new URLSearchParams(locationURL.search);
        searchParams.set("uuid", uuid);
        navigate(`?${searchParams.toString()}`);        
    }    

    return (
        <>
            <div className="true-false-question-view" id={uuid} onClick={setNewParams}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        <FlagUpdate flags={flags} updateFlags={updateFlags} uuid={uuid} grading={grading} number={questionNumber}/>
                    </div>
                    <div className="pts">
                        { (grading ? Number(pointsEarned).toFixed(2) + " / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="code-response-area-wrapper">
                    {
                        requestLifeCycleTwo ?

                        <>
                            <div className="loading-zip-container-2">
                                <Loading />
                            </div>
                        </>

                        :

                        <>
                            <div className={`code-response-area ${fullscreen ? (grading ? "fullscreen-editor-side-bar" : "fullscreen-editor") : ""}`} style={{ backgroundColor: isDarkTheme ? "var(--vs-background)" : "white", color: isDarkTheme ? "white" : "var(--almost-black)"}}>
                                <div className="code-editor-header">
                                    <FullScreenToggler updateFullscreen={updateFullscreen} fullscreen={fullscreen}/>
                                    <DarkThemeQuestion isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
                                </div>                        
                                <Editor
                                    language={grading ? language : (useSyntaxHighlighting && useSyntax ? language : "plaintext")}
                                    value={answer}
                                    theme={ isDarkTheme ? "vs-dark" : "vs"}
                                    onChange={(value) => handleChange(value)}
                                    options={{
                                        fontFamily: "Consolas, 'Courier New', monospace", // Default font family
                                        fontSize: 14, // Default font size (14px)
                                        tabSize: 8,         // Set tab length to 4 spaces
                                        fontWeight: "500", // Default font weight
                                        suggestOnTriggerCharacters: useAutocompletion,
                                        quickSuggestions: useAutocompletion,
                                        readOnly: grading,
                                        contextmenu: false, // Disable right-click context menu
                                        parameterHints: useAutocompletion, // Disable parameter hints for function calls
                                        autoClosingBrackets: true, // Disable automatic closing brackets       
                                    }}
                                />
                                <div className="code-editor-header" />
                            </div>
                        </>
                    }
                </div>
                {
                    grading && endedExam && determineIfWeHaveATestToShow() && (  
                        <div className="true-false-top lambda-results-container">
                            {

                                results && typeof results === "object" ?
                                
                                Object.keys(results).map((id) => {                            

                                    if (id in testsMap) {
                                        const test_result = results[id];
                                        const original_test = testsMap[id];

                                        return <ASingleTestLambdaGrading passPoints={original_test.passPoints} greyedOut={status === "RUNNING"} key={id} testName={original_test.testName} pointsEarned={original_test[test_result.passed ? "passPoints" : "failPoints"]} output={test_result.output} passed={test_result.passed}/>
                                    }
                                })

                                :

                                <div style={{ color: status === "RUNNING" ? "var(--gray-six)" : "" }}>
                                    {results}
                                </div>
                            }                                
                        </div>                            
                    )
                }
                {
                    !grading && language && language !== "plaintext" && allowedAttempts !== null && allowedAttempts !== undefined && allowedAttempts !== 0 && testsMap && Object.keys(testsMap).length > 0 && (
                        <div className="select-all-that-apply-array" style={{ marginTop: "10px" }}>
                            <RunNewTests contents={contents} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} questionsToSave={questionsToSave} updateQuestionsToSave={updateQuestionsToSave} status={status} attemptInput={attempt ? attempt : 0} allowedAttempts={allowedAttempts ? allowedAttempts : 0} testsMap={testsMap} uuid={uuid} results={results} language={language}/>
                        </div>                    
                    )
                }
                <Explanation grading={grading} useExplanation={useExplanation} explanation={explanation}/>
            </div>
        </>
    );
}

export default LambdaResponseView;