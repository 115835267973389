import React from "react";
import { MathJaxContext } from "better-react-mathjax";
import Loading from "./loading";
import ViewStatusEvaluation from "./viewStatusEvaluation";
import CustomExamWrapperView from "./customExamWrapperView";
import mathJaxConfig from "./mathJaxConfig";

const CustomExamExtendedWrapperView = ({ requestLifeCycleFour, flags, updateFlags, contentsNeedToBeSaved, updateContentsNeedToBeSaved, fetchError, coupledProgrammingQuestions, updateAttempt, status, allowedAttemptsProgramming, attempt, error, testsConfigDict, questionsToSave, requestLifeCycleTwo, isDarkTheme, updateIsDarkTheme, useSyntaxHighlighting, updateContents, requestLifeCycle, questions, updateQuestionsToSave, contents }) => {

    return (
        <>
            {
                requestLifeCycle

                ?

                    <> 
                        <div className="total">
                            <Loading />
                        </div>
                    </>

                :

                <>
                    {
                        questions && questions.length > 0

                        ?

                        <>
                            <div className="custom-programming-exam-wrapper" style={{ position: "relative" }}>
                                <MathJaxContext config={mathJaxConfig}>
                                    <CustomExamWrapperView requestLifeCycleFour={requestLifeCycleFour} flags={flags} updateFlags={updateFlags} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} fetchError={fetchError} coupledProgrammingQuestions={coupledProgrammingQuestions} updateAttempt={updateAttempt} status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} error={error} testsConfigDict={testsConfigDict} questionsToSave={questionsToSave} requestLifeCycleTwo={requestLifeCycleTwo} requestLifeCycle={requestLifeCycle} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={useSyntaxHighlighting} updateContents={updateContents} updateQuestionsToSave={updateQuestionsToSave} contents={contents} questions={questions} grading={false}/>
                                </MathJaxContext>
                            </div>
                        </>

                        :

                        <>
                            <div className="total">
                                <ViewStatusEvaluation text={"No questions found for this evaluation."}/>
                            </div>
                        </>

                    }
                </>

            }
        </>
    );
}
export default CustomExamExtendedWrapperView;