import React from "react";
import useWindowWidth from "./useWindowWidth";
import CustomExamExtendedWrapperView from "./customExamExtendedWrapperView";
import PdfTakeEval from "./pdfTakeEval";
import TypesOfLocationEnum from "./typesOfLocationEnum";

const SplitScreenView = ({ requestLifeCycleFour, flags, updateFlags, contentsNeedToBeSaved, updateContentsNeedToBeSaved, fetchError, coupledProgrammingQuestions, updateAttempt, status, allowedAttemptsProgramming, attempt, error, testsConfigDict, questionsToSave, isDarkTheme, updateIsDarkTheme, useSyntaxHighlighting, updateContents, requestLifeCycle, questions, updateQuestionsToSave, contents, requestLifeCycleTwo, pdf}) => {
    const width = useWindowWidth(1000);
    return (
        <>
            <div className="split-screen-container" style={{ flexDirection: width ? "column" : "row" }}>
                {
                    width 

                    ?

                    <>
                        <div className={`wrapper-for-split-screen-custom-exam ${width ? "new-height" : ""}`}>
                            <CustomExamExtendedWrapperView requestLifeCycleFour={requestLifeCycleFour} flags={flags} updateFlags={updateFlags} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved}  fetchError={fetchError} coupledProgrammingQuestions={coupledProgrammingQuestions} updateAttempt={updateAttempt} status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} error={error} testsConfigDict={testsConfigDict} questionsToSave={questionsToSave} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={useSyntaxHighlighting} updateContents={updateContents} requestLifeCycle={requestLifeCycle} questions={questions} updateQuestionsToSave={updateQuestionsToSave} contents={contents}/>
                        </div>
                        <PdfTakeEval currentViewer={TypesOfLocationEnum.SPLIT_SCREEN} requestLifeCycle={requestLifeCycleTwo} pdf={pdf}/>                        
                    </>

                    :

                    <>
                        <PdfTakeEval currentViewer={TypesOfLocationEnum.SPLIT_SCREEN} requestLifeCycle={requestLifeCycleTwo} pdf={pdf}/>
                        <div className={`wrapper-for-split-screen-custom-exam ${width ? "new-height" : ""}`}>
                            <CustomExamExtendedWrapperView requestLifeCycleFour={requestLifeCycleFour} flags={flags} updateFlags={updateFlags} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved}  fetchError={fetchError} coupledProgrammingQuestions={coupledProgrammingQuestions} updateAttempt={updateAttempt} status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} error={error} testsConfigDict={testsConfigDict} questionsToSave={questionsToSave} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={useSyntaxHighlighting} updateContents={updateContents} requestLifeCycle={requestLifeCycle} questions={questions} updateQuestionsToSave={updateQuestionsToSave} contents={contents}/>
                        </div>
                    </>
                }
            </div>
        </>
    );
}

export default SplitScreenView;