import React from "react";
import useWindowWidth from "./useWindowWidth";
import Loading from "./loading";
import convertDate from "./convertDate";
import FeedbackWindow from "./feedbackWindow";
import formatTime from "./convertCompletionTime";
import moment from "moment-timezone";
import InstructorUploaded from "./instructorUploaded";
import handleStatusDate from "./handleStatusDate";
import HandleStatus from "./handleStatus";
import CustomExamViewGradedWrapper from "./customExamViewGradedWrapper";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';

const LeftInfoAssessmentPage = ({ status, stillProcessing, endedExam, error, testsConfigDict, requestLifeCycleTwo, pdfUrls, gradedQuestions, isDarkTheme, updateIsDarkTheme, contents, assessmentInformation, requestLifeCycle }) => {
    const width = useWindowWidth(1000);

    const findStatusTitle = () => {
        if ('submission' in assessmentInformation && 'endedExam' in assessmentInformation.submission) {
            if (assessmentInformation.submission.endedExam) {
                if (assessmentInformation.published) {
                    return "Submitted"
                } else {
                    return "Unpublished"
                }
            } else {
                return "Unfinished"
            }        
        } else {
            return handleStatusDate(assessmentInformation.start, assessmentInformation.end)
        }
    }

    const findStatusIcon = () => {
        if ('submission' in assessmentInformation && 'endedExam' in assessmentInformation.submission) {
            if (assessmentInformation.submission.endedExam) {
                return HandleStatus("Graded");
            } else {
                return HandleStatus("Ungraded");
            }
        } else {
            return HandleStatus(handleStatusDate(assessmentInformation.start, assessmentInformation.end))
        }
    }

    const findStart = () => {
        return convertDate(assessmentInformation.start, false)
    }

    const findEnd = () => {
        return convertDate(assessmentInformation.end, false)
    }

    const findCompletionTime = () => {
        if ('submission' in assessmentInformation && 'startTime' in assessmentInformation.submission && 'endTime' in assessmentInformation.submission && assessmentInformation.submission.endedExam) {
            const { startTime, endTime } = assessmentInformation.submission;

            // Use Moment to parse the start and end times
            const start = moment(startTime);
            const end = moment(endTime);

            // Calculate the difference in minutes
            const differenceInMinutes = end.diff(start, 'minutes');

            // Pass the difference in minutes to formatTime function
            return (
                <>
                    <div className="side-nav-info-header">
                        Completion Time
                    </div>
                    <div className="text-side-nav">
                        {formatTime(differenceInMinutes)}
                    </div> 
                </>
            );
        } else {
            return <></>;
        }
    }

    const findScore = () => {
        if ('submission' in assessmentInformation && assessmentInformation.submission.endedExam) {
            if ('pointsEarned' in assessmentInformation.submission && 'totalPoints' in assessmentInformation.submission) {
                return (
                    <>
                        <div className="points">
                            { Number(assessmentInformation.submission.pointsEarned).toFixed(2) + " / " +  Number(assessmentInformation.submission.totalPoints).toFixed(2) }
                            {
                                stillProcessing && (
                                    <Tippy content={<span style={{ fontSize: '12px' }}>Score processing: latest changes may not be reflected.</span>} placement={"right"}>
                                        <div style={{ cursor: "pointer" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ff3f3f" viewBox="0 0 16 16">
                                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                                            </svg>
                                        </div>
                                    </Tippy>
                                )
                            }                            
                        </div>
                    </>
                );
            }
        }
    }

    return (
        <>
            <div className={`left-info fullscreen ${width ? "small" : ""}`} style={{ paddingBottom: "0px" }}>
                <div className="left-info-content" style={{ height: width ? "400px" : "100%", border: "none", position: "relative" }}>
                    {
                        requestLifeCycle

                        ?

                        <>
                            <div className="loading-container">
                                <Loading />
                            </div>  
                        </>

                        :

                        <>
                            {
                                assessmentInformation && Object.keys(assessmentInformation).length > 0

                                ?
                                
                                <>
                                    <div className="side-nav-info-header extra">
                                        { assessmentInformation.name }
                                    </div>
                                    {
                                        findScore()
                                    }
                                    <div className="side-nav-info-header">
                                        Status
                                    </div>
                                    <div className="text-side-nav display-2">
                                        { findStatusIcon() } { findStatusTitle() }
                                    </div>
                                    <div className="side-nav-info-header">
                                        Schedule
                                    </div>
                                    <div className="text-side-nav display">
                                        {
                                            assessmentInformation.duration && assessmentInformation?.duration !== -1 && (
                                                <div> { "Duration: " + (assessmentInformation.duration + " " + assessmentInformation.metric) } </div>
                                            )
                                        }                                        
                                        <div> { "Available From: " + findStart() } </div>
                                        <div> { "Available Until: " + findEnd() } </div>
                                        {
                                            assessmentInformation.attempts > 0 && assessmentInformation.submission.attempt ?

                                            <>
                                                <div> { "Attempt: " + (assessmentInformation.submission ? assessmentInformation.submission.attempt : "0") + "/" + assessmentInformation.attempts } </div>
                                            </>

                                            :

                                            <>
                                            </>
                                        }
                                    </div> 
                                    {
                                        assessmentInformation.extraTime > 0 ?

                                        <>
                                            <div className="side-nav-info-header">
                                                Extension
                                            </div>
                                            <div className="text-side-nav display">
                                                <div> { assessmentInformation.extraTime + " " + assessmentInformation.extraTimeMetric } </div>
                                            </div>   
                                        </>
                                        
                                        :

                                        <>
                                        </>
                                        
                                    }                         
                                    {
                                        findCompletionTime()
                                    }                                  
                                    {
                                        'submission' in assessmentInformation
        
                                        ?
                                        
                                            (
                                                assessmentInformation.submission.instructorUploaded
        
                                                ?
        
                                                <>
                                                    <div className="side-nav-info-header" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", columnGap: "10px", width: "100%" }}>
                                                        Instructor Uploaded
                                                        <InstructorUploaded />
                                                    </div>
                                                </>
                                                
        
                                                :
        
                                                <>
                                                </>
                                            )
        
                                            :
        
                                            <>
                                            </>
                                    }
                                    <div className="side-nav-info-header">
                                        Feedback
                                    </div>
                                    <FeedbackWindow feedback={ 'submission' in assessmentInformation ? 'feedback' in assessmentInformation.submission ? assessmentInformation.submission.feedback : "" : "" }   />
                                </>

                                :

                                <>
                                </>

                            }
                        </>                                            
                    }
                </div>
            </div>
            {
                requestLifeCycle ?

                <>
                    <div className={`loading-container ${width ? "pdf-content-container small" : "" }`}>
                        <Loading />
                    </div>  
                </>

                :

                <>
                    {
                        assessmentInformation.published ?

                        <CustomExamViewGradedWrapper status={status} endedExam={endedExam} error={error} testsConfigDict={testsConfigDict} requestLifeCycleTwo={requestLifeCycleTwo} requestLifeCycle={requestLifeCycle} pdfUrls={pdfUrls} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} gradedQuestions={gradedQuestions} contents={contents}/>

                        :

                        <>
                            <div className={ `pdf-content-container  ${ width ? "small" : "" }` } style={{ minHeight: width ? "600px" : ""}}>
                                <div className="loading-zip-container" style={{ flexDirection: "column", columnGap: "10px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16 16">
                                        <path fill="#0B73FE" stroke="#0B73FE" strokeWidth=".3" d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707z"/>
                                    </svg>
                                    Submission resources are unavailable at this time
                                </div>
                            </div>
                        </>
                    }   
                </>
            }                     
        </>
    );
}

export default LeftInfoAssessmentPage;