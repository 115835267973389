import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SemesterDashboard from "./components/semesterDashboard";
import CourseDashboard from "./components/courseDashboard";
import AccountSettings from "./components/accountSettings";
import AssessmentPage from "./components/assessmentPage";
import TakeEvaluation from "./components/takeEvaluation";
import PrivacyPolicy from "./components/privacyPolicy";
import TermsAndConditions from "./components/termsAndConditions";

function App() {
    // Fetch user role
    useEffect(() => {
        const fetchRole = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_USER_API_URL + "/get-role", {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (data.role === "professor") {
                    if (process.env.REACT_APP_IS_DESKTOP !== "true") {
                        window.location.href = process.env.REACT_APP_403_REDIRECT_URL; // redirect to professor page
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchRole();
    }, []);

    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<SemesterDashboard />} />
                    <Route path="/:courseCode" element={<CourseDashboard />} />
                    <Route path="/:courseCode/:assessmentCode" element={<AssessmentPage />} />
                    <Route path="/:courseCode/:assessmentCode/:email" element={<AssessmentPage />} />
                    <Route path="/:courseCode/:assessmentCode/take-evaluation" element={<TakeEvaluation />} />
                    <Route path="/account-settings" element={<AccountSettings />} />
                    <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
                    <Route path="/terms-and-conditions" element={ <TermsAndConditions /> } />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default App;