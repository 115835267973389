import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "./loading";
import ASingleTestLambdaGrading from "./aSingleTestLambdaGrading";
import Tippy from "@tippyjs/react";
import zipUpContents from "./zipUpContents";
import ReloadButton from "./reloadButton";

const RunNewTests = ({ contents, contentsNeedToBeSaved, updateContentsNeedToBeSaved, questionsToSave, updateQuestionsToSave, status, attemptInput, testsMap, results, uuid, language, allowedAttempts }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [error, setError] = useState(null);
    const [attempt, setAttempt] = useState(null);
    const [componentStatus, setComponentStatus] = useState(null);
    const [componenetResults, setComponenetResults] = useState(null);

    const setNewParams = () => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("uuid", uuid);
        navigate(`?${searchParams.toString()}`);
    }
 
    const handleClick = async () => {
        if (requestLifeCycle || (attempt >= allowedAttempts && allowedAttempts !== -1)) {
            return;
        }

        try {
            setRequestLifeCycle(true);
            setError(null);
            await saveAnswers();
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/test-question-student/${courseCode}/${assessmentCode}/${uuid}/${language}`
            const urlOptions = {
                method: "POST",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                setAttempt(prev => prev + 1);
                setNewParams();
                setComponentStatus('RUNNING');

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                setError(data.detail);
                setComponentStatus('COMPLETED');
            }

        } catch (error) {
            console.log(error);
            setError(error.message);
            setComponentStatus('COMPLETED');
        } finally {
            setRequestLifeCycle(false);
        }
    }

    const findRemaining = () => {
        const result = allowedAttempts - attempt;

        if (result < 0) {
            return 0;
        }

        return result;
    }

    const findStatus = () => {
        if (componentStatus === "RUNNING"){
            return " is processing (refresh periodically)"
        }
    }

    useEffect(() => {
        if (status) {
            setComponentStatus(status);
        }
    }, [status])

    useEffect(() => {
        if (attemptInput) {
            setAttempt(attemptInput);
        }
        
    }, [attemptInput])

    const thereExistsSavedChanges = questionsToSave && (Object.keys(questionsToSave).length > 0 || contentsNeedToBeSaved?.save);

    const saveAnswers = async () => {
        if (!thereExistsSavedChanges) {
            return;
        }

        // create formdata
        const formData = new FormData();
        formData.append('courseCode', courseCode);
        formData.append('assessmentCode', assessmentCode);
        formData.append('answers', JSON.stringify(Object.keys(questionsToSave).map(key => ({...questionsToSave[key], uuid: key}))));

        // check if we have a zip file
        if (contentsNeedToBeSaved?.save) {
            formData.append('contents', await zipUpContents(contents));
        }

        const url = process.env.REACT_APP_SUBMISSION_API_URL  + "/temporary-save-answers"
        const urlOptions = {
            method: "POST",
            credentials: "include",
            body: formData
        }

        try {
            const response = await fetch(url, urlOptions);
            if (response.status === 200) {
                updateQuestionsToSave({});
                updateContentsNeedToBeSaved({
                    save: false
                });
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else if (response.status === 450 || response.status === 451 || response.status === 452 || response.status === 453 || response.status === 455) {
                navigate(`/${courseCode}/${assessmentCode}`);
            }

        } catch (error) {
            console.log(error)
        }
    }

    const fetchResults = async () => {
        if (requestLifeCycle) {
            return;
        }

        try {
            setRequestLifeCycle(true);
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/fetch-lambda-results-student/${courseCode}/${assessmentCode}/${uuid}`
            const urlOptions = {
                method: "GET",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                setComponentStatus(data.status);
                setAttempt(data.attempt);
                setComponenetResults(data.results);
                setError(null);
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                setError(data.detail)
                setComponentStatus('COMPLETED');
                setComponenetResults(null);
            }

        } catch (error) {
            console.log(error);
            setError(error.message);
            setComponentStatus('COMPLETED');
            setComponenetResults(null);
        } finally {
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        if (results) {
            setComponenetResults(results);
        }

    }, [results])

    return (
        <>
            {
                requestLifeCycle ?

                <div className="loading-zip-container" style={{ maxHeight: "50px", height: "50px", minHeight: "50px" }}>
                    <Loading />
                </div>

                :

                testsMap && typeof testsMap === "object" && Object.keys(testsMap).length > 0 && (
                    <div className="run-new">
                        <div className="tests-center" style={{ marginBottom: "10px", columnGap: "10px" }}>
                            <div onClick={fetchResults}>
                                <ReloadButton typeOfString={"Lambda"}/>
                            </div>
                            <div className={`top-right-item run-tests ${attempt >= allowedAttempts && allowedAttempts !== -1 ? "greyed-out-run-tests" : "" }`} onClick={handleClick} style={{ width: "fit-content", paddingLeft: "10px", paddingRight: "10px" }}>
                                Run Tests {allowedAttempts !== -1 ? `(${findRemaining()} left)` : ""}
                                <Tippy content={<div style={{ fontSize: '12px', textAlign: "center" }}>Running the lambda autograder will evaluate only the tests assigned for this question.</div>} placement={"top"}>
                                    <div className="docs-wrapper-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 16 16">
                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </div>
                                </Tippy>
                            </div>
                        </div>
                        {
                            error ?

                            <div style={{ color: componentStatus === "RUNNING" ? "var(--gray-six)" : "" }}>
                                {error}
                            </div>

                            :

                            attempt > 0 && (
                                <>
                                    Additional Attempt {attempt}{allowedAttempts === -1 ? "" : "/" +  allowedAttempts}{findStatus()}:
                                    <div className="true-false-top lambda-results-container">
                                        {
                                            typeof componenetResults === "object" && componenetResults !== null ? 

                                            Object.keys(componenetResults).map((id) => {                            

                                                if (id in testsMap) {
                                                    const test_result = componenetResults[id];
                                                    const original_test = testsMap[id];

                                                    return <ASingleTestLambdaGrading passPoints={original_test.passPoints} greyedOut={componentStatus === "RUNNING"} key={id} testName={original_test.testName} pointsEarned={original_test[test_result.passed ? "passPoints" : "failPoints"]} output={test_result.output} passed={test_result.passed}/>
                                                }
                                            })

                                            :
                                            
                                            <div style={{ color: componentStatus === "RUNNING" ? "var(--gray-six)" : "" }}>
                                                {componenetResults}
                                            </div>
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                )
            }
        </>    
    );
}

export default RunNewTests;