import React, { useEffect, useState } from "react";
import TrueFalseQuestionView from "./trueFalseQuestionView";
import SelectAllThatApplyView from "./selectAllThatApplyView";
import TextResponseView from "./textResponseView";
import CodeResponseView from "./codeResponseView";
import MultipleChoiceView from "./multipleChoiceView";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import ContextBlockView from "./contextBlockView";
import CompleteOnPaperView from "./completeOnPaperView";
import LambdaResponseView from "./lambdaResponseView";
import { useLocation } from "react-router-dom";

const CustomExamWrapperView = ({ requestLifeCycleFour, flags, updateFlags, contentsNeedToBeSaved, updateContentsNeedToBeSaved, fetchError, coupledProgrammingQuestions, updateAttempt, status, allowedAttemptsProgramming, attempt, endedExam, error, testsConfigDict, questionsToSave, requestLifeCycleTwo, pdfUrls, isDarkTheme, updateIsDarkTheme, useSyntaxHighlighting, updateContents, contents, questions, updateQuestionsToSave, grading }) => {       
    const locationURL = useLocation();    
    
    const typeOfQuestionToReturn = (question, index) => {
        switch (question.questionType) {
            case TypesOfQuestionsEnum.TRUE_FALSE: {
                return <TrueFalseQuestionView flags={flags} updateFlags={updateFlags} questionsToSave={questionsToSave} isDarkTheme={isDarkTheme} useExplanation={question.useExplanation} explanation={question.explanation} studentAnswer={question.studentAnswer} bubbledAnswer={question.bubbled} pointsEarned={question.pointsEarned} grading={grading} bubbledPassed={question.bubbled} uuid={question.uuid} updateQuestionsToSave={updateQuestionsToSave} key={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.SELECT_ALL: {
                return <SelectAllThatApplyView flags={flags} updateFlags={updateFlags} questionsToSave={questionsToSave} isDarkTheme={isDarkTheme} useExplanation={question.useExplanation} explanation={question.explanation} studentAnswer={question.studentAnswer} answerArrayAnswer={question.answerArray} grading={grading} pointsEarned={question.pointsEarned} answerArrayPassed={question.answerArray} uuid={question.uuid} updateQuestionsToSave={updateQuestionsToSave} key={question.uuid} questionNumber={index} points={question.points} question={question.question} options={question.options}/>
            }
            case TypesOfQuestionsEnum.SHORT_RESPONSE: {
                return <TextResponseView flags={flags} updateFlags={updateFlags} questionsToSave={questionsToSave} isDarkTheme={isDarkTheme}useExplanation={question.useExplanation} explanation={question.explanation} grading={grading} answerString={question.answerString} studentAnswer={question.studentAnswer} answerPassed={question.answerString} uuid={question.uuid} updateQuestionsToSave={updateQuestionsToSave} key={question.uuid} pointsEarned={question.pointsEarned} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.SCAN_AND_GRADE: {
                return <CompleteOnPaperView flags={flags} updateFlags={updateFlags} isDarkTheme={isDarkTheme} pdfUrls={pdfUrls} useExplanation={question.useExplanation} explanation={question.explanation} grading={grading} answerPassed={question.answer} uuid={question.uuid} updateQuestionsToSave={updateQuestionsToSave} key={question.uuid} pointsEarned={question.pointsEarned} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.MULTIPLE_CHOICE: {
                return <MultipleChoiceView flags={flags} updateFlags={updateFlags} questionsToSave={questionsToSave} isDarkTheme={isDarkTheme} useExplanation={question.useExplanation} explanation={question.explanation} studentAnswer={question.studentAnswer} answerIndexAnswer={question.answerIndex} grading={grading} answerIndexPassed={question.answerIndex} pointsEarned={question.pointsEarned} uuid={question.uuid} updateQuestionsToSave={updateQuestionsToSave} key={question.uuid} questionNumber={index} points={question.points} question={question.question} options={question.options}/>
            }
            case TypesOfQuestionsEnum.PROGRAMMING: {
                return <CodeResponseView requestLifeCycleFour={requestLifeCycleFour} flags={flags} updateFlags={updateFlags} fetchError={fetchError} updateAttempt={updateAttempt} coupledProgrammingQuestions={coupledProgrammingQuestions} status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} endedExam={endedExam} tests={question.tests} error={error} testsConfigDict={testsConfigDict} requestLifeCycleTwo={requestLifeCycleTwo} useExplanation={question.useExplanation} explanation={question.explanation} useSyntaxHighlighting={useSyntaxHighlighting} grading={grading} studentAnswer={question.studentAnswer} uuid={question.uuid} pointsEarned={question.pointsEarned} updateQuestionsToSave={updateQuestionsToSave} location={question.location} updateContents={updateContents} contents={contents} useAutocompletion={question.useAutocompletion} key={question.uuid} questionNumber={index} points={question.points} question={question.question} language={question.language} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} usingZipFile={false}/>
            }
            case TypesOfQuestionsEnum.CONTEXT_BLOCK: {
                return <ContextBlockView flags={flags} updateFlags={updateFlags} isDarkTheme={isDarkTheme} grading={grading} uuid={question.uuid} key={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.LAMBDA: {
                return <LambdaResponseView flags={flags} updateFlags={updateFlags} contents={contents} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} questionsToSave={questionsToSave} status={question.status} attempt={question.attempt} allowedAttempts={question.allowedAttempts} endedExam={endedExam} updateQuestionsToSave={updateQuestionsToSave} testsMap={question.testsMap} results={question.results} useAutocompletion={question.useAutocompletion} useSyntax={question.useSyntax} requestLifeCycleTwo={requestLifeCycleTwo} useSyntaxHighlighting={useSyntaxHighlighting} studentAnswer={question.studentAnswer} pointsEarned={question.pointsEarned} grading={grading} uuid={question.uuid} key={question.uuid} questionNumber={index} points={question.points} question={question.question} language={question.language} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
            }
            default: {
                return <></>
            }
        }
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(locationURL.search)
        const uuid = searchParams.get("uuid")
        let timer = null;

        if (uuid && questions) {
            // could also have id bottom of page
            let element = null;
            
            const escapedSelector = CSS.escape(uuid);
            element = document.querySelector("#" + escapedSelector);

            if (element && !grading) {
                const offset = 75; // Margin above the element                

                timer = setTimeout(() => {
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    window.scrollTo({ top: elementPosition - offset, behavior: "instant" });
                }, 100);                
            }
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        }

    }, [])

    return (
        <>
            {
                questions && Object.keys(questions).length > 0

                ?

                <>
                    <div className="custom-exam-wrapper-view">
                        {
                            questions
                                .map((question, index) => (
                                        typeOfQuestionToReturn(question, index + 1)
                                )                                    
                            )
                        }
                    </div>
                </>

                :

                <>
                    <div className="no-questions">
                        <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                            <div className="loading-zip-container" style={{ flexDirection: "column", columnGap: "10px", textAlign: "center"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16 16">
                                    <path fill="#0B73FE" stroke="#0B73FE" strokeWidth=".3" d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707z"/>
                                </svg>
                                Submission resources are unavailable at this time
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default CustomExamWrapperView;