import React, { useState } from "react";
import SingleQ from "./singleQ";

const Questions = ({ flags, updateFlags, questionsToSave, contentsNeedToBeSaved, questions }) => {
    const [open, setOpen] = useState(true);

    return (
        <div className="questions-top-level" style={{ width: open ? "250px" : "", paddingBottom: !open ? "10px" : "", paddingTop: !open ? "10px" : "" }}>
            {
                open ? 

                <>
                    <div className="questions-right-container">
                        Questions:
                        <div className="icon" onClick={() => setOpen(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 16 16">
                                <path fill="currentColor" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                            </svg>
                        </div>
                    </div>
                    <div className="embedded-qs-wrapper">
                        {
                            questions && questions.length > 0 && questions.map((question, index) => {
                                return <SingleQ key={question?.uuid} updateFlags={updateFlags} question={question} questionsToSave={questionsToSave} number={index + 1} isFlag={question?.uuid && question.uuid in flags} contentsNeedToBeSaved={contentsNeedToBeSaved}/>
                            })
                        }
                    </div>
                </>

                :

                <>
                    <div className="burger-in-exam" onClick={() => setOpen(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                            <path fill="currentColor" stroke="currentColor" strokeWidth=".4" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                        </svg>
                    </div>
                </>

            }
        </div>
    );
}

export default Questions;