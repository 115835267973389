import React from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";
import { useLocation, useNavigate } from "react-router-dom";
import FlagUpdate from "./flagUpdate";

const ContextBlockView = ({ flags, updateFlags, isDarkTheme, questionNumber, question, points, uuid, grading }) => {
    const navigate = useNavigate();
    const locationURL = useLocation();

    const setNewParams = () => {        
        const searchParams = new URLSearchParams(locationURL.search);
        searchParams.set("uuid", uuid);
        navigate(`?${searchParams.toString()}`);        
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid} onClick={setNewParams}>
                <div className="question-number">
                    <div className="grid-number-container">
                        C{ questionNumber }
                        <FlagUpdate context={true} flags={flags} updateFlags={updateFlags} uuid={uuid} grading={grading} number={questionNumber}/>
                    </div>
                    <div className="pts">
                        { Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
            </div>
        </>
    );
}

export default ContextBlockView;