import Tippy from "@tippyjs/react";
import React from "react";
import TypesOfLocationEnum from "./typesOfLocationEnum";

const LocationSettingsItem = (props) => {
    const factory = () => {
        switch (props.path) {
            case TypesOfLocationEnum.CUSTOM_EXAM: return "Evaluation"
            case TypesOfLocationEnum.SETTINGS: return "Details And Evaluation"

            default: return "Blank Tab"
        }
    }

    return (
        <>
            <Tippy content={factory()} placement="top">
                <div className="location-settings-hover-item" onClick={() => {
                    props.updateCurrentViewer(props.path)
                }}>
                    { props.svgOne }
                </div>
            </Tippy>
        </>
    );
}

export default LocationSettingsItem;