import React from "react";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import Tippy from "@tippyjs/react";
import { useLocation, useNavigate } from "react-router-dom";

const SingleQ = ({ updateFlags, isFlag, number, question, questionsToSave, contentsNeedToBeSaved, locationsToSAve }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const typeOfQ = question.questionType === TypesOfQuestionsEnum.CONTEXT_BLOCK ? "Context" : "Question";

    const handleClick = () => {
        if (question?.uuid) {
            const newParams = new URLSearchParams(location.search);
            newParams.set('uuid', question.uuid);            

            // could also have id bottom of page
            let element = null;
            
            const escapedSelector = CSS.escape(question.uuid);
            element = document.querySelector("#" + escapedSelector);

            if (element) {
                const offset = 75; // Margin above the element
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({ top: elementPosition - offset, behavior: "smooth" });
            }

        }
    }
        
    return (        
        <div className="each-q-container">
            {
                isFlag ?

                <Tippy content={<span style={{ fontSize: '12px' }}>Unflag {typeOfQ} {number}</span>} placement={"left"}>
                    <div style={{ cursor: "pointer" }} onClick={() => {
                        if (isFlag) {
                            updateFlags((prev) => {
                                delete prev[question.uuid];

                                return {
                                    ...prev
                                }
                            })
                        }
                    }}>
                        <div className="flag-wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FFA500" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                            </svg>
                        </div>
                    </div>
                </Tippy>

                :

                <>
                    <div className="flag-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--gray-half)" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg>
                    </div>
                </>

            }       
            {
                (question?.uuid in questionsToSave || (contentsNeedToBeSaved?.save && question?.questionType === TypesOfQuestionsEnum.PROGRAMMING && (contentsNeedToBeSaved?.locations && question?.location && question.location in contentsNeedToBeSaved?.locations))) ? 

                <Tippy content={<span style={{ fontSize: '12px' }}>{typeOfQ} changes are not saved</span>} placement={"left"}>
                    <div style={{ cursor: "pointer" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF4500" class="bi bi-x-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                    </div>
                </Tippy>

                :

                <Tippy content={<span style={{ fontSize: '12px' }}>{typeOfQ} changes are saved</span>} placement={"left"}>
                    <div style={{ cursor: "pointer" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={"#32CD32"} viewBox="0 0 16 16">
                            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
                            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                        </svg>
                    </div>
                </Tippy>

            }
            <div className="question-navigate" onClick={handleClick}>
                {typeOfQ} {number}
            </div>
        </div>
    );
}

export default SingleQ;